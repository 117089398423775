@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.featuredStars {
    padding: 100px 0 75px;
    position: relative;

    @media (max-width: $sp_bp) {
        padding: 60px 0 40px;
    }

    &__title {
        @include titleDotted;
    }

    &__text {
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        margin: 0 auto 70px;
        max-width: 625px;
        font-family: "Roboto";

        @media (max-width: $sp_bp) {
            margin: 0 auto 40px;
        }
    }

    &__slider {
        height: 470px;
    }
}

.sliderItem {
    padding-right: 26px;

    @media (max-width: $sp_bp) {
        padding-right: 0;
    }

    &__card {
        background-color: transparent;
        border: 2px solid #335;
        box-sizing: content-box;
        width: 270px;

        @media (max-width: $xdt_bp) {
            width: 225px;
        }

        @media (max-width: $dt_bp) {
            width: 255px;
        }

        @media (max-width: $tb_bp) {
            width: 200px;
        }

        @media (max-width: $sp_bp) {
            margin: 0 auto;
            width: 270px;

            background-color: #fff;
            border: 0;

            &__img {
                height: 364px;

                @media (max-width: $xdt_bp) {
                    height: 314px;
                }

                @media (max-width: $dt_bp) {
                    height: 344px;
                }

                @media (max-width: $sp_bp) {
                    height: 364px;
                }
            }

            .sliderItem__card__title {
                color: #12102c;
            }

            .sliderItem__card__social i::before {
                color: #12102c;
            }
        }

        &__img {
            height: 360px;
            width: 100%;
            object-fit: cover;

            @media (max-width: $xdt_bp) {
                height: 310px;
            }

            @media (max-width: $dt_bp) {
                height: 340px;
            }

            @media (max-width: $sp_bp) {
                height: 360px;
            }
        }

        &__title {
            font-weight: bold;
            font-size: 18px;
            line-height: 19px;
            color: #fff;
            margin: 28px 30px 10px;
        }

        &__social {
            padding: 0 16px 20px;
            display: flex;
            justify-content: flex-end;

            i {
                margin-left: 16px;
                cursor: pointer;
            }
        }

        &__current {
            background-color: #fff;
            border: 0;

            .sliderItem__card__img {
                height: 364px;

                @media (max-width: $xdt_bp) {
                    height: 314px;
                }

                @media (max-width: $dt_bp) {
                    height: 344px;
                }

                @media (max-width: $sp_bp) {
                    height: 360px;
                }
            }

            .sliderItem__card__title {
                color: #12102c;
            }

            .sliderItem__card__social i::before {
                color: #12102c;
            }
        }
    }
}

.controls {
    display: flex;
    justify-content: center;
    padding-top: 50px;

    @media (max-width: $sp_bp) {
        padding-top: 15px;
        margin: 0 auto;
        max-width: 200px;
        justify-content: space-evenly;
    }

    &__arrow {
        background: transparent;
        border: 0;
        cursor: pointer;

        &__left {
            transform: rotate(180deg);
        }
    }

    &__dot {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        background: none;
        border: 0;
        cursor: pointer;
        padding: 0 10px;
        transition: all 0.3s;
        font-family: "Pixel Cyr Normal";

        &:focus {
            outline: none;
        }

        &__active {
            font-size: 30px;
            padding: 0 20px;
        }

        @media (max-width: $sp_bp) {
            display: none;
        }
    }
}
