@font-face {
    font-family: "Montserrat Regular";
    src: url("../fonts/montserrat_regular.eot"); /* IE 9 Compatibility Mode */
    src: url("../fonts/montserrat_regular.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("../fonts/montserrat_regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/montserrat_regular.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("../fonts/montserrat_regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/montserrat_regular.svg#montserrat_regular") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Pixel Cyr Normal";
    src: url("../fonts/pixel_cyr_normal.eot"); /* IE 9 Compatibility Mode */
    src: url("../fonts/pixel_cyr_normal.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("../fonts/pixel_cyr_normal.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/pixel_cyr_normal.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("../fonts/pixel_cyr_normal.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/pixel_cyr_normal.svg#pixel_cyr_normal") format("svg"); /* Chrome < 4, Legacy iOS */
}
@font-face {
    font-family: "VAG World Bold";
    src: url("../fonts/vag_world_bold.eot"); /* IE 9 Compatibility Mode */
    src: url("../fonts/vag_world_bold.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("../fonts/vag_world_bold.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/vag_world_bold.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("../fonts/vag_world_bold.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/vag_world_bold.svg#vag_world_bold") format("svg"); /* Chrome < 4, Legacy iOS */
}
