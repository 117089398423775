@import "../../assets/styles/mixins";

.join_dis {
    padding: 38px 0 34px;
    position: relative;
    background: linear-gradient(90.45deg, #330095 9.68%, #6508ae 77.88%);

    @media (max-width: $tb_bp) {
        padding: 30px 0 27px;
    }

    @media (max-width: $sp_bp) {
        padding: 22px 0 17px;
    }

    &__content {
        display: flex;
        position: relative;

        @media (max-width: $dt_bp) {
            padding: 0 50px;
        }

        @media (max-width: $xdt_bp) {
            flex-direction: column;
        }

        @media (max-width: $sp_bp) {
            padding: 0;
        }

        &__item {
            padding: 0;
            width: 100%;
            text-align: center;
        }
    }

    &__link {
        transition: opacity 0.4s;

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }

    &__img {
        height: 90px;

        @media (max-width: $tb_bp) {
            height: 68px;
        }

        @media (max-width: $sp_bp) {
            height: 52px;
        }
    }
}
