@import "../../assets/styles/mixins";

.aboutVideo {
    padding: 45px 0 15px;
    position: relative;

    @media (max-width: $sp_bp) {
        padding: 240px 0 25px;
    }

    &__title {
        @include title;
        margin-bottom: 42px;
    }

    &__text {
        color: #ffffff;
        font-family: "Montserrat Regular", sans-serif;
        line-height: 1.62;

        @media (max-width: $sp_bp) {
            font-size: 14px;
            line-height: 140%;
        }
    }

    &__content {
        display: flex;
        position: relative;

        @media (max-width: $dt_bp) {
            padding: 0 50px;
        }

        @media (max-width: $xdt_bp) {
            flex-direction: column;
        }

        @media (max-width: $sp_bp) {
            padding: 0;
        }

        &__item {
            padding: 75px 75px 75px 0;
            width: 45%;

            @media (max-width: $xdt_bp) {
                padding: 30px 60px 50px;
                width: 100%;
            }

            @media (max-width: $dt_bp) {
                padding: 0;
            }

            @media (max-width: $tb_bp) {
                max-width: 520px;
                margin: auto;
            }
        }

        &__video {
            padding: 115px 0 0 15px;

            @media (max-width: $xdt_bp) {
                padding: 0;
                text-align: center;
            }

            @media (max-width: $tb_bp) {
                margin-top: 15px;
            }

            img {
                max-width: 560px;
                cursor: pointer;

                @media (max-width: $tb_bp) {
                    width: 100%;
                }
            }
        }

        &__iframeContainer {
            display: none;

            iframe {
                @media (max-width: $tb_bp) {
                    width: 100%;
                }
            }
        }

        &__iframe {
            iframe {
                @media (max-width: $tb_bp) {
                    width: 100%;
                }
            }
        }
    }

    &__starsImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media (max-width: $sp_bp) {
            display: none;
        }

        &__greenstars {
            position: absolute;
            bottom: 6%;
            transform: translate(50%, 42%);

            @media (min-width: $xs_bp) {
                right: 15%;
            }
        }
    }
}
