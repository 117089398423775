@import "../../assets/styles/mixins";

.collections {
    padding: 75px;

    @media (max-width: $sp_bp) {
        padding: 40px 0 20px;
    }

    &__title {
        @include titleDotted;
    }

    &__subtitle {
        font-family: "VAG World Bold";
        font-weight: bold;
        font-size: 45px;
        line-height: 110%;
        color: #fff;

        @media (max-width: $tb_bp) {
            font-size: 42px;
        }

        @media (max-width: $sp_bp) {
            font-size: 36px;
            margin: 10px 0;
        }

        @media (max-width: $xs_bp) {
            font-size: 32px;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $tb_bp) {
            flex-direction: column;
        }

        @media (max-width: $sp_bp) {
            margin-bottom: 25px;
        }
    }

    &__text {
        font-weight: normal;
        font-size: 16px;
        line-height: 170%;
        color: #ffffff;
        max-width: 780px;
        font-family: "Roboto";

        @media (max-width: $xdt_bp) {
            max-width: 75%;
        }

        @media (max-width: $dt_bp) {
            max-width: 70%;
        }

        @media (max-width: $tb_bp) {
            max-width: 100%;
        }
    }

    &__link {
        visibility: hidden;
        cursor: pointer;
        @include button;

        @media (max-width: $dt_bp) {
            text-align: center;
        }

        @media (max-width: $tb_bp) {
            text-align: left;
            visibility: visible;
        }
    }

    &__linkTop {
        cursor: pointer;
        @include button;

        @media (max-width: $dt_bp) {
            text-align: center;
        }

        @media (max-width: $tb_bp) {
            text-align: left;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 45px -13px 0;

        @media (max-width: $sp_bp) {
            margin-top: 0;
            flex-direction: column;
        }

        &__itemContainer {
            position: relative;
            margin: 0 0 32px 0;
            flex-basis: 33.333333%;

            @media (max-width: $xdt_bp) {
                width: 31%;
            }

            @media (max-width: $sp_bp) {
                width: 280px;
                margin: 0 auto 20px;
            }

            @media (max-width: $tb_bp) {
                .collections__list__item {
                    border: 0;

                    &__content {
                        background: #f5f6fa;

                        a {
                            visibility: visible;
                        }
                    }

                    &__img {
                        height: 353px;

                        @media (max-width: $xdt_bp) {
                            height: 303px;
                        }

                        @media (max-width: $tb_bp) {
                            height: 193px;
                        }

                        @media (max-width: $sp_bp) {
                            height: 263px;
                        }
                    }

                    &__text,
                    &__name,
                    &__price {
                        color: #000;
                    }
                }

                .collections__list__itemBg {
                    display: block;
                }
            }

            &:hover,
            &:active {
                .collections__link {
                    visibility: visible;
                }
                .collections__list__item {
                    outline: 3px solid transparent;

                    &:after {
                        background: #5800ff;
                        content: '';
                        width: 5px;
                        height: 100%;
                        position: absolute;
                        top: 1px;
                        right: -2px;
                        z-index: 2;
                        transform: skew(0deg, 45deg);
                    }

                    &:before {
                        content: '';
                        width: 100%;
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        z-index: 2;
                        height: 5px;
                        background: #5800ff;
                        transform: skew(45deg, 0deg);
                    }

                    &__content {
                        background: #f5f6fa;
                        border-bottom-left-radius: 2px 5px;

                        a {
                            visibility: visible;
                        }
                    }

                    //&__img {
                    //    height: 353px;
                    //
                    //    @media (max-width: $xdt_bp) {
                    //        height: 303px;
                    //    }
                    //
                    //    @media (max-width: $tb_bp) {
                    //        height: 193px;
                    //    }
                    //
                    //    @media (max-width: $sp_bp) {
                    //        height: 263px;
                    //    }
                    //}

                    &__text,
                    &__name,
                    &__price {
                        color: #000;
                    }
                }

                // .collections__list__itemBg {
                    // display: block;
                // }
            }
        }

        &__itemBg {
            position: absolute;
            top: 2px;
            left: 3px;
            width: calc(100% + 2px);
            height: 100%;
            content: "";
            //border: 5px solid #4300c3;
            border-top-right-radius: 7px;
            border-bottom-left-radius: 7px;
            display: none;
        }

        &__item {
            width: 370px;
            outline: 3px solid #fff;
            z-index: 1;
            position: relative;

            @media (max-width: $xdt_bp) {
                width: 100%;
            }

            &__img {
                width: 100%;
                height: 350px;
                object-fit: cover;

                @media (max-width: $xdt_bp) {
                    height: 300px;
                }

                @media (max-width: $tb_bp) {
                    height: 190px;
                }

                @media (max-width: $sp_bp) {
                    height: 260px;
                }
            }

            &__content {
                padding: 30px 15px 15px;
                margin-top: -5px;

                a {
                    visibility: hidden;
                }
            }

            &__name {
                font-family: "VAG World Bold";
                font-size: 24px;
                line-height: 150%;
                color: #ffffff;
                margin: 0;
            }

            &__text {
                font-family: "Roboto";
                font-size: 12px;
                line-height: 160%;
                color: #fff;
                margin: 10px 0 18px;

                @media (max-width: $tb_bp) {
                    margin-bottom: 10px;
                }
            }

            &__price {
                font-family: "Pixel Cyr Normal";
                font-size: 35px;
                line-height: 110%;
                color: #ffffff;
                margin: 0;

                @media (max-width: $tb_bp) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__more {
        width: 100%;
        margin-top: 20px;
        text-align: center;

        &__button {
            @include button;
            display: inline-block;
            padding-left: 40px;
            padding-right: 40px;

            @media (max-width: $dt_bp) {
                text-align: center;
            }

            @media (max-width: $tb_bp) {
                text-align: left;
            }
        }
    }
}
