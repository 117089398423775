@import "../../assets/styles/mixins";

.trail {
    padding: 94px 58px 128px;
    position: relative;
    color: #ffffff;
    background: linear-gradient(106.26deg, rgba(23, 13, 82, 0.9) 17.82%, rgba(21, 3, 52, 0.9) 68.41%);
    max-width: 1286px;
    margin: auto;
    max-width: 1286px;
    margin: 0 auto;

    @media (max-width: $xdt_bp) {
        max-width: 1060px;
        padding: 94px 30px 128px;
    }

    @media (max-width: $dt_bp) {
        max-width: calc(100% - 100px);
    }

    @media (max-width: $sp_bp) {
        max-width: none;
    }

    @media (max-width: $sp_bp) {
        padding: 52px 0 56px;
    }

    &__wrap {
        position: relative;
        padding-bottom: 100px;

        @media (max-width: $sp_bp) {
            padding: 0 20px 60px;
        }
    }

    &__title {
        @include titleDotted;
        margin-bottom: 45px;
    }

    &__subtitle {
        font-family: "Montserrat Regular";
        text-align: center;

        @media (max-width: $sp_bp) {
            font-size: 14px;
            line-height: 160%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 56px;

        @media (max-width: $sp_bp) {
            max-width: 420px;
            margin: 33px auto 0;
            padding: 0 10px;
        }
    }

    &__item {
        position: relative;
        height: 115px;
        width: 100%;
        z-index: 1;

        @media (max-width: $xdt_bp) {
            position: static;
            margin-bottom: 30px;
            height: auto;
            min-height: 115px;
        }

        @media (max-width: $sp_bp) {
            margin-bottom: 0;
        }

        &:nth-of-type(1) &__wrap {
            left: 6px;
            top: 0;

            @media (max-width: $sp_bp) {
                align-items: flex-start;
                margin-bottom: 37px;
            }

            .trail__item__text {
                max-width: 419px;

                @media (max-width: $xdt_bp) {
                    max-width: none;
                }
            }
        }

        &:nth-of-type(2) &__wrap {
            right: 4px;
            top: -16px;

            .trail__item__text {
                max-width: 240px;

                @media (max-width: $xdt_bp) {
                    max-width: none;
                }
            }

            .trail__item__num {
                &::after {
                    @media (max-width: $sp_bp) {
                        bottom: -102px;
                    }
                }
            }
        }

        &:nth-of-type(3) &__wrap {
            left: 176px;
            top: 21px;

            .trail__item__num {
                &::after {
                    @media (max-width: $sp_bp) {
                        bottom: -89px;
                    }
                }
            }
        }

        &:nth-of-type(4) &__wrap {
            right: 61px;
            top: -10px;
        }

        &:nth-of-type(5) &__wrap {
            left: 433px;
            top: 50px;

            .trail__item__num {
                &::after {
                    @media (max-width: $sp_bp) {
                        bottom: -87px;
                    }
                }
            }
        }

        &:nth-of-type(6) &__wrap {
            left: 3px;
            top: 131px;
        }

        &:nth-of-type(7) &__wrap {
            right: -20px;
            top: -7px;

            @media (max-width: $sp_bp) {
                position: relative;
                right: 0;
                top: 0;
            }

            &::before {
                @media (max-width: $sp_bp) {
                    position: absolute;
                    left: 38px;
                    bottom: -50px;
                    background-image: linear-gradient(to right, #ffbd49 16%, rgba(255, 255, 0, 0) 0%);
                    background-position: calc(100% - 5px);
                    background-size: 28px 5px;
                    background-repeat: repeat-x;
                    width: calc(50% - 38px);
                    height: 50px;
                    content: "";
                }
            }
            &::after {
                @media (max-width: $sp_bp) {
                    position: absolute;
                    left: calc(50% - 48px);
                    bottom: -70px;
                    background-image: linear-gradient(to bottom, #ffbd49 20%, rgba(255, 255, 0, 0) 0%);
                    background-position: right;
                    background-size: 5px 25px;
                    background-repeat: repeat-y;
                    width: 48px;
                    height: 24px;
                    content: "";
                }
            }

            .trail__item__num {
                &::after {
                    @media (max-width: $sp_bp) {
                        display: none;
                    }
                }
            }
        }

        &:nth-of-type(8) {
            margin-top: 158px;

            @media (max-width: $xdt_bp) {
                margin-top: 9px;
            }

            @media (max-width: $sp_bp) {
                margin-top: 32px;
            }

            .trail__item__text {
                max-width: 400px;

                @media (max-width: $xdt_bp) {
                    max-width: none;
                }
            }
        }

        &:nth-of-type(8) &__wrap {
            left: 81px;
            flex-direction: row-reverse;

            @media (max-width: $xdt_bp) {
                flex-direction: row;
            }

            @media (max-width: $sp_bp) {
                flex-direction: column;
            }

            .trail__item__text {
                font-weight: bold;
                letter-spacing: 0.3px;
                margin-left: 0;
                margin-right: 36px;
                text-align: end;

                @media (max-width: $xdt_bp) {
                    margin-left: 35px;
                    margin-right: 0;
                    text-align: left;
                }

                @media (max-width: $sp_bp) {
                    max-width: 450px;
                    margin-top: 20px;
                    margin-left: 0;
                    text-align: center;
                }
            }

            .trail__item__num {
                background: linear-gradient(122.99deg, #ffa800 -1.27%, #ffce83 97.56%);
                box-shadow: none;
                font-size: 60px;
                min-height: 141px;
                min-width: 141px;

                @media (max-width: $sp_bp) {
                    min-height: 140px;
                    width: 100%;
                    max-width: 260px;
                }

                &::after {
                    @media (max-width: $xdt_bp) {
                        display: none;
                    }
                }
            }
        }

        &__wrap {
            z-index: 1;
            display: flex;
            align-items: center;
            position: absolute;

            @media (max-width: $xdt_bp) {
                position: static;
                justify-content: center;
            }

            @media (max-width: $sp_bp) {
                justify-content: flex-start;
            }
        }

        &__num {
            position: relative;
            font-family: "Pixel Cyr Normal";
            font-size: 45px;
            background: #a42bed;
            box-shadow: 2px 2px 0px #f092f2;
            min-width: 115px;
            min-height: 115px;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                @media (max-width: $xdt_bp) {
                    position: absolute;
                    bottom: -85px;
                    left: 57px;
                    display: block;
                    content: "";
                    height: 80px;
                    border-right: 3px dotted #ffbd49;
                }

                @media (max-width: $sp_bp) {
                    z-index: -1;
                    border: none;
                    left: 0;
                    bottom: -99px;
                    background-image: linear-gradient(to bottom, #ffbd49 20%, rgba(255, 255, 0, 0) 0%);
                    background-position: right;
                    background-size: 5px 25px;
                    background-repeat: repeat-y;
                    width: 45px;
                    height: 100px;
                    content: "";
                }
            }

            @media (max-width: $sp_bp) {
                min-width: 80px;
                min-height: 80px;
                font-size: 31px;
                line-height: 110%;
            }
        }

        &__text {
            font-family: "Montserrat Regular";
            line-height: 160%;
            margin-left: 35px;
            max-width: 269px;

            @media (max-width: $xdt_bp) {
                width: 400px;
                max-width: none;
            }

            @media (max-width: $sp_bp) {
                font-size: 14px;
                line-height: 140%;
                width: auto;
                margin: 0 0 0 20px;
            }
        }
    }

    &__planetsBg {
        position: absolute;
        top: -289px;
        left: 0;
        height: 100%;

        @media (max-width: $sp_bp) {
            top: auto;
            bottom: 151px;
            left: 0;
        }

        &__img {
            max-width: 100%;
            height: 1930px;
            object-fit: cover;

            @media (max-width: $sp_bp) {
                display: none;
            }
        }

        &__mobileImg {
            display: none;

            @media (max-width: $sp_bp) {
                display: block;
                max-width: 100%;
                height: 1674px;
                object-fit: cover;
                object-position: 66%;
            }
        }
    }

    &__dotsBg {
        position: absolute;
        top: 109px;
        left: 38px;

        @media (max-width: $xdt_bp) {
            display: none;
        }

        &__img {
            max-width: 100%;
        }
    }

    &__starsImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media (max-width: $sp_bp) {
            display: none;
        }

        img {
            position: absolute;
        }

        &__redstars {
            bottom: 7.5%;
            left: 0.6%;

            @media (max-width: $xs_bp) {
                bottom: -10.5%;
            }
        }

        &__greenstars {
            top: -21%;
            right: 3.25%;

            @media (max-width: $xs_bp) {
                top: -18%;
            }
        }
    }
}
