@import "../../assets/styles/mixins";

.about {
    position: relative;
    padding: 40px 0 70px;
    color: #fff;

    @media (min-width: $xs_bp) {
        padding: 40px 0 90px;
    }

    @media (min-width: $xdt_bp) {
        padding: 140px 0 135px;
    }

    &__img {
        max-width: 460px;

        @media (min-width: $dt_bp) {
            margin-top: 60px;
        }

        @media (min-width: $xdt_bp) {
            padding-left: 7px;
            margin-top: 20px;
            max-width: none;
        }
    }

    &__title {
        @include title;
        padding-left: 21px;
        margin-bottom: 44px;

        @media (max-width: $dt_bp) {
            justify-content: center;
            padding: 0;
        }
    }

    &__subtitle {
        font-family: "Montserrat Regular", sans-serif;
        padding-left: 21px;
        font-size: 16px;
        margin-bottom: 40px;

        @media (max-width: $dt_bp) {
            padding: 0;
        }

        @media (max-width: $sp_bp) {
            font-size: 14px;
            line-height: 160%;
            margin-bottom: 25px;
        }
    }

    &__content {
        display: flex;
        position: relative;

        @media (max-width: $dt_bp) {
            padding: 0 50px;
        }

        @media (max-width: $dt_bp) {
            flex-direction: column;
        }

        @media (max-width: $sp_bp) {
            padding: 0;
        }

        &__item {
            position: relative;
            padding: 10px;
            width: 50%;
            z-index: 2;

            @media (max-width: $xdt_bp) {
                &:first-child {
                    width: 40%;
                }

                &:last-child {
                    width: 60%;
                }
            }

            @media (max-width: $dt_bp) {
                padding: 30px 60px;

                &:first-child {
                    order: 2;
                    width: 100%;
                    padding: 0 60px 60px;
                }

                &:last-child {
                    order: 1;
                    width: 100%;
                }
            }

            @media (max-width: $tb_bp) {
                padding: 0;
                width: 100%;
                max-width: 520px;
                margin: auto;
            }

            @media (max-width: $sp_bp) {
                &:first-child {
                    padding: 0 0 20px;

                    img {
                        width: 100%;
                    }
                }
            }

            ul {
                list-style-image: url("../../assets/images/plus-icon.svg");

                @media (max-width: $sp_bp) {
                    padding-left: 16px;
                }

                li {
                    padding-left: 20px;
                    font-family: "Montserrat Regular", sans-serif;
                    font-size: 16px;
                    line-height: 1.6;
                    margin-bottom: 20px;

                    @media (max-width: $sp_bp) {
                        font-size: 14px;
                        line-height: 160%;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    &__link {
        @include button;
        margin: auto;
        min-width: 135px;

        @media (max-width: $xs_bp) {
            margin-top: 10px;
        }

        &__yellow {
            position: absolute;
            top: 48%;
            left: 28%;
            background: #ffb631;
            border: 3px solid rgba(0, 0, 0, 0.5);
            min-width: unset;
            width: 117px;
            min-height: 117px;
            font-size: 16px;

            @media (max-width: $xs_bp) {
                left: 5px;
                right: 5px;
                top: 42%;
            }
        }
    }

    &__starsImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media (max-width: $sp_bp) {
            display: none;
        }

        img {
            position: absolute;
        }

        &__redstars {
            top: 30%;
            left: 20%;
            transform: translate(-50%, -50%);

            @media (min-width: $dt_bp) {
                top: 1%;
                left: 28.5%;
            }
        }
    }
}
