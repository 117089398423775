@import "../../assets/styles/variables";

.pager {
    height: 100vh;
    overflow-y: scroll;
}

.pagerNav {
    height: 100vh;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 9%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1650px) {
        right: 4%;
    }

    @media (max-width: $sp_bp) {
        display: none;
    }

    &::before {
        content: "";
        width: 3px;
        height: 100%;
        margin-left: 78px;
        z-index: 2;
        background: linear-gradient(
            173.59deg,
            #fffa83 5.33%,
            #83ff88 21.48%,
            #47e5e5 39.63%,
            #6f1afb 58.82%,
            #e70eb7 84.23%
        );
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 320px;

        @media (max-height: $dt_bp) {
            padding-bottom: 25px;
        }

        @media (max-height: $tb_bp) {
            min-height: 300px;
            padding-bottom: 90px;
        }
    }

    &__link {
        font-family: "Pixel Cyr Normal";
        background: none;
        border: none;
        color: #fff;
        font-size: 14px;
        line-height: 28px;
        z-index: 2;
        width: 30px;
        cursor: pointer;
        transition: all 0.4s;
        position: relative;

        @media (max-width: $tb_bp) {
            span {
                display: none;
            }
        }

        &:hover,
        &:active {
            opacity: 0.7;
        }

        &:first-child {
            margin-top: -15px;
        }

        &:last-child {
            margin-bottom: -15px;
        }

        &__pagerImage,
        &__pagerImageActive {
            position: absolute;
            left: 54px;
            top: 0;
            transform: translateX(-50%);
            object-fit: none;
            height: 34px;
            width: 33px;
            transition: opacity 0.4s;
        }

        &__pagerImageActive {
            opacity: 0;
        }

        &__active {
            color: #00ffff;
            font-size: 24px;
            cursor: unset;

            &:hover,
            &:active {
                opacity: 1;
            }

            .pagerNav__link__pagerImage {
                opacity: 0;
            }

            .pagerNav__link__pagerImageActive {
                opacity: 1;
            }
        }
    }

    &__bgLine {
        content: "";
        width: 3px;
        height: 100%;
        left: 78px;
        position: absolute;
        z-index: 1;
        background-color: #2a2842;
    }

    &::after {
        content: "";
        height: 100%;
    }
}
