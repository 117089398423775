@import "./variables";

@mixin button {
    font-family: "Pixel Cyr Normal";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 18px;
    width: max-content;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #5800ff;
    border: 3px solid rgba(0, 0, 0, 0.5);
    margin: 0 6px;
    transition: opacity 0.4s;
    letter-spacing: 1px;

    &:hover,
    &:active {
        opacity: 0.7;
    }
}

@mixin title {
    font-weight: bold;
    font-size: 60px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #fff;
    text-shadow: 0px 4px 0px #e800ed;
    margin: 0 0 70px;
    font-family: "VAG World Bold";

    @media (max-width: $tb_bp) {
        font-size: 56px;
    }

    @media (max-width: $sp_bp) {
        font-size: 45px;
        margin-bottom: 35px;
    }
}

@mixin titleDotted {
    font-weight: bold;
    font-size: 60px;
    line-height: 110%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-shadow: 0px 4px 0px #e800ed;
    margin: 0 0 70px;
    font-family: "VAG World Bold";

    @media (max-width: $sp_bp) {
        text-align: center;
        font-size: 45px;
        margin-bottom: 30px;
    }

    &::after,
    &::before {
        content: "\220E";
        font-size: 29px;
        text-shadow: 4px 2px 0px #e800ed;

        @media (max-width: $sp_bp) {
            font-size: 23px;
            margin-top: -10px;
        }
    }

    &::after {
        margin-left: 40px;

        @media (max-width: $sp_bp) {
            margin-left: 20px;
        }
    }
    &::before {
        margin-right: 40px;

        @media (max-width: $sp_bp) {
            margin-right: 20px;
        }
    }
}
