@import "../../assets/styles/mixins";

$count: 73;
$countColor: $count;
$countMobile: 20;
$countColorMobile: $countMobile;
$fallDuration: 17;
$opacityDuration: 24;
$colorDuration: 4;

$color1: #fffa83;
$color2: #83ff88;
$color3: #47e5e5;
$color4: #6f1afb;
$color5: #e70eb7;
$color6: #fc2b2b;
$color7: #ff9345;
$color8: #2f1dff;
$color9: #fff;
$colors: $color1, $color2, $color3, $color4, $color5, $color6, $color7, $color8, $color9;

.header {
    width: 100%;
    height: 100vh;
    font-size: 50px;
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $sp_bp) {
        height: auto;
        padding-top: 115px;
    }

    &__stars {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -50px;
        display: block;
        font-size: 14px;
        overflow: hidden;
        pointer-events: none;
    }

    &__star {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;

        width: 0;
        height: 0;

        &__icon {
            align-self: center;

            &__small {
                font-size: 4px;
            }
        }

        @media (min-width: $sp_bp) {
            @while ($count > 0) {
                &:nth-child(#{$count}) {
                    left: percentage(random(100) / 100);
                    top: (random(100)) + vh;
                }

                &:nth-child(#{$count}) {
                    animation: animation-starflake-#{$count} steps(20) infinite;
                    animation-duration: $fallDuration + random($fallDuration * 10) / 10 + s;
                    animation-delay: random(2 * $fallDuration * 10) / 10 - (2 * $fallDuration) + s;
                }

                @keyframes animation-starflake-#{$count} {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate((360) * ((random(2) - 1)) + deg);
                    }
                }

                &:nth-child(#{$count}) &__icon {
                    opacity: 0.85;
                    animation: animation-opacity-#{$count} steps(12) infinite;
                    animation-duration: $opacityDuration + random($opacityDuration * 10) / 10 + s;
                    animation-delay: random(2 * $opacityDuration * 10) / 10 - (2 * $opacityDuration) + s;

                    $key: random(length($colors));
                    $nth: nth($colors, $key);
                    $random: $nth !default;

                    &::before {
                        color: $random;
                    }
                }

                @keyframes animation-opacity-#{$count} {
                    0% {
                        opacity: 0.85;
                    }
                    50% {
                        opacity: 0.05;
                    }
                    100% {
                        opacity: 0.85;
                    }
                }

                &:nth-child(#{$countColor}) &__icon::before {
                    animation: animation-color-#{$countColor} steps(12) infinite;
                    animation-duration: $colorDuration + random($colorDuration * 10) / 10 + s;
                    animation-delay: random(2 * $colorDuration * 10) / 10 - (2 * $colorDuration) + s;
                }

                @keyframes animation-color-#{$countColor} {
                    $key: random(length($colors));
                    $nth: nth($colors, $key);
                    $random: $nth !default;
                    $key2: random(length($colors));
                    $nth2: nth($colors, $key2);
                    $random2: $nth2 !default;
                    $key3: random(length($colors));
                    $nth3: nth($colors, $key3);
                    $random3: $nth3 !default;

                    0% {
                        color: $random;
                    }
                    33% {
                        color: $random2;
                    }
                    67% {
                        color: $random3;
                    }
                    100% {
                        color: $random;
                    }
                }
                $count: $count - 1;
                $countColor: $countColor - (random(2) + 1);
            }
        }

        @media (max-width: $sp_bp) {
            @while ($countMobile > 0) {
                &:nth-child(#{$countMobile}) {
                    left: percentage(random(100) / 100);
                    top: (random(100)) + vh;
                }

                &:nth-child(#{$countMobile}) {
                    animation: animation-starflake-#{$countMobile} steps(20) infinite;
                    animation-duration: $fallDuration + random($fallDuration * 10) / 10 + s;
                    animation-delay: random(2 * $fallDuration * 10) / 10 - (2 * $fallDuration) + s;
                }

                @keyframes animation-starflake-#{$countMobile} {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate((360) * ((random(2) - 1)) + deg);
                    }
                }

                &:nth-child(#{$countMobile}) &__icon {
                    opacity: 0.85;
                    animation: animation-opacity-#{$countMobile} steps(12) infinite;
                    animation-duration: $opacityDuration + random($opacityDuration * 10) / 10 + s;
                    animation-delay: random(2 * $opacityDuration * 10) / 10 - (2 * $opacityDuration) + s;

                    $key: random(length($colors));
                    $nth: nth($colors, $key);
                    $random: $nth !default;

                    &::before {
                        color: $random;
                    }
                }

                @keyframes animation-opacity-#{$countMobile} {
                    0% {
                        opacity: 0.85;
                    }
                    50% {
                        opacity: 0.05;
                    }
                    100% {
                        opacity: 0.85;
                    }
                }

                &:nth-child(#{$countColorMobile}) &__icon::before {
                    animation: animation-color-#{$countColorMobile} steps(12) infinite;
                    animation-duration: $colorDuration + random($colorDuration * 10) / 10 + s;
                    animation-delay: random(2 * $colorDuration * 10) / 10 - (2 * $colorDuration) + s;
                }

                @keyframes animation-color-#{$countColorMobile} {
                    $key: random(length($colors));
                    $nth: nth($colors, $key);
                    $random: $nth !default;
                    $key2: random(length($colors));
                    $nth2: nth($colors, $key2);
                    $random2: $nth2 !default;
                    $key3: random(length($colors));
                    $nth3: nth($colors, $key3);
                    $random3: $nth3 !default;

                    0% {
                        color: $random;
                    }
                    33% {
                        color: $random2;
                    }
                    67% {
                        color: $random3;
                    }
                    100% {
                        color: $random;
                    }
                }
                $countMobile: $countMobile - 1;
                $countColorMobile: $countColorMobile - (random(2) + 1);
            }
        }
    }
    &__menu {
        position: absolute;
        top: 35px;
        z-index: 1;
        width: 700px; // 612px
        max-width: 100%;
        text-align: center;

        @media (max-width: $sp_bp) {
            top: 11px;
            width: 320px;
        }

        &__list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__item {
            margin: 0 15px;

            @media (min-width: $sp_bp) {
                flex: 1;
            }

            @media (max-width: $sp_bp) {
                line-height: 30px;
                margin: 0 11px;
            }

            &:last-child {
                flex: 0.6;
                margin: 0;

                @media (max-width: $sp_bp) {
                    flex: 0.35;
                }
            }

            a {
                font-family: "VAG World Bold";
                font-size: 18px;
                font-weight: bold;
                color: white;
                text-decoration: none;
                text-shadow: 0 3px 0 #e800ed;
                vertical-align: middle;
                white-space: nowrap;
                transition: all 0.2s;

                @media (max-width: $sp_bp) {
                    text-shadow: 0 1px 0 #e800ed;
                    font-size: 16px;
                }

                &:hover {
                    text-decoration: none;
                    text-shadow: none;
                    color: #ff00d6;
                }
            }
        }

        &__discord {
            transition: all 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        &__discord,
        &__discord img {
            display: inline-block;
            width: 50px;
            margin: 8px 25px 0 0;

            @media (max-width: $sp_bp) {
                width: 20px;
                margin: 5px 0 0 0;
            }
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        width: 700px; // 612px
        text-align: center;
        padding-top: 10px;

        @media (max-width: $sp_bp) {
            width: 100%;
            padding: 10px 40px;
            max-width: 560px;
            min-height: 450px;
        }

        @media (max-width: $xs_bp) {
            padding: 10px 20px;
            max-width: 320px;
            min-height: 500px;
        }

        &__logo {
            width: 65px;

            @media (max-width: $sp_bp) {
                width: 43px;
            }
        }

        &__title {
            font-family: "VAG World Bold";
            font-size: 96px;
            // text-shadow: 0px 4px 0px #e800ed;
            margin: 25px 0 50px 0;
            line-height: 100%;

            @media (max-width: $sp_bp) {
                margin: 0 0 23px;
                font-size: 48px;
            }
        }

        &__text {
            font-size: 16px;
            line-height: 29px;
            margin: 20px 0;
            text-align: center;

            @media (max-width: $sp_bp) {
                font-size: 14px;
                line-height: 140%;
                margin: 18px 0;
            }
        }

        &__profile {
            text-decoration: none;
            color: white;
            text-shadow: 0px 3px 0px #e800ed;

            &:hover {
                text-decoration: none;
                text-shadow: none;
                color: #e800ed;
            }
        }

        &__warning {
            font-size: 18px;
            background: #e800ed;
            color: white;
            padding: 10px 40px;
            margin: 40px 0 40px 0;
        }

        &__links {
            margin-top: 50px;
            display: flex;
            justify-content: center;

            @media (max-width: $sp_bp) {
                margin-top: 36px;
            }
        }

        &__links__rows {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__link {
            @include button;

            &__transparent {
                border: 2px solid #ffffff;
                background-color: transparent;
            }
        }

        &__salestats {
            margin-top: 25px;
            font-size: 24px;

            @media (max-width: $sp_bp) {
                margin-top: 10px;
                font-size: 16px;

                span {
                    display: block;
                }
            }

            strong {
                text-shadow: 0px 3px 0px #e800ed;
            }
        }
    }

    &__purchase {
        display: flex;
        align-items: center;
        justify-content: center;

        &__row {
            display: flex;
            justify-content: center;
        }

        &__input {
            @include button;

            font-size: 18px;
            border: 2px solid #ffffff;
            background-color: transparent;
            width: 120px;
            text-align: center;
        }

        &__button {
            @include button;
            width: 120px;
        }

        &__info {
            font-size: 20px;
            color: #ffffff;
            margin-top: 20px;
        }

        &__error {
            font-size: 20px;
            color: #e8ed00;
            margin-top: 20px;
        }
    }

    &__snails {
        position: absolute;
        bottom: 0;
        transform: translateY(684px);
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;

        @media (max-width: 1600px) {
            left: auto;
            right: 200px;
        }

        @media (max-width: $xdt_bp) {
            right: 350px;
        }

        @media (max-width: $dt_bp) {
            right: 580px;
        }

        @media (max-width: $tb_bp) {
            right: 750px;
        }

        @media (max-width: $sp_bp) {
            padding: 0;
            right: 0;
            top: auto;
            bottom: 0;
            transform: translateY(145%);
        }

        @media (max-width: $xs_bp) {
            transform: translateY(128.5%);
        }

        &__desktop {
            @media (max-width: $sp_bp) {
                display: none;
            }
        }

        &__mobile {
            display: none;

            @media (max-width: $sp_bp) {
                display: block;
            }
        }
    }

    &__starsImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media (max-width: $xs_bp) {
            overflow: hidden;
        }

        img {
            position: absolute;
        }

        &__redstars {
            top: 20%;
            left: 30%;
            transform: translate(-50%, -50%);

            @media (max-width: $sp_bp) {
                display: none;
            }
        }

        &__greenstars {
            top: 32%;
            right: 22%;
            transform: translate(50%, -50%);

            @media (max-width: $sp_bp) {
                display: none;
            }
        }
    }
}
