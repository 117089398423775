@import "../assets/styles/variables";

.app {
    background: $main_color;
}

.featuredStars {
    font-family: "VAG World Bold";
    width: 100%;
    height: 500px;
    border: 1px solid #000;
    color: #fff;
    font-size: 50px;
}

.footer {
    width: 100%;
    height: 322px;
    background: #c4c4c4;
    font-family: "Roboto";
    color: #fff;
    font-size: 50px;
}
